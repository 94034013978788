import * as yup from 'yup';

export const depaMatchFormSchema = yup.object().shape({
  clientName: yup.string().required('El nombre es requerido'),
  clientEmail: yup
    .string()
    .email('Ingresa un email valido')
    .required('El email es requerido'),
  salary: yup.string().required('El promedio de renta es requerido'),
  debt: yup.string().required('La deuda de crédito es requerida'),
});
