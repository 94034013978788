import { useCallback, useState, memo } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  TransitLayer,
} from '@react-google-maps/api';
import { API_KEY_GOOGLE_MAPS } from '@/utils/constants';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const mapOptions = {
  styles: [
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#444444',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          color: '#f2f2f2',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'visible',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#46bcec',
        },
        {
          visibility: 'on',
        },
      ],
    },
  ],
};

interface Coordinates {
  coordinates: {
    lat: number;
    lng: number;
  };
  setCoordinates?: any;
}

function AppMap({ coordinates, setCoordinates }: Coordinates) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-property-page',
    googleMapsApiKey: API_KEY_GOOGLE_MAPS as string,
  });
  const onMarkerDragEnd = useCallback(
    function callback(event: any) {
      // Obtener las nuevas coordenadas después de arrastrar el marcador
      const newCoordinates = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      // Actualizar las coordenadas usando setCoordinates
      setCoordinates(newCoordinates);
    },
    [setCoordinates],
  );
  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={coordinates}
      zoom={17}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={mapOptions}
    >
      {map && <Marker position={coordinates} onDragEnd={onMarkerDragEnd} draggable={true} />}
      {map && <TransitLayer />}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default memo(AppMap);
