import React, { useState } from 'react';
import { Button, CardModal } from '../ui/src';
import type { ReactNode } from 'react';

interface useConfirmationModalProps {
  title?: string;
  content: string | ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

type ModalType = [() => void, ReactNode, () => void];

export const useConfirmationModal = ({
  title,
  content,
  onConfirm,
  confirmText,
  cancelText,
  onCancel,
}: useConfirmationModalProps): ModalType => {
  const [open, setOpen] = useState(false);

  const cancel = (): void => {
    setOpen(false);
    onCancel && onCancel();
  };

  const confirm = () => {
    setOpen(false);
    onConfirm();
  };

  const ContextHolder = (): ReactNode => {
    return (
      <CardModal title={title || 'Confirmar acción'} open={open}>
        <div className="p-5 text-center">
          <span className="text-lg">{content}</span>
          <div className="flex justify-center gap-8 pt-6">
            <Button variant="link" color="primary" onClick={cancel}>
              {cancelText || 'Cancelar'}
            </Button>
            <Button variant="fill" color="primary" onClick={confirm}>
              {confirmText || 'Confirmar'}
            </Button>
          </div>
        </div>
      </CardModal>
    );
  };

  return [() => setOpen(true), ContextHolder(), cancel];
};
