import { classNames } from '@/utils/class-names';
import React from 'react';

const NewInput = React.forwardRef((props: any, ref) => {
  const errorMessage = props.errors?.[props.name]?.message;
  const { className, ...rest } = props;
  const isCheckBox = props.type === 'checkbox';
  return (
    <div className={`${isCheckBox ? 'flex items-center' : ''} relative`}>
      {props.label && !isCheckBox && (
        <label
          htmlFor={props.name}
          className="absolute -top-2 bg-white px-1 block text-[15px] font-['Raleway'] font-medium text-primary-600 ml-4"
        >
          {props.label}
        </label>
      )}
      {!props.options && (
        <input
          ref={ref}
          onChange={(e) =>
            props.onChange(isCheckBox ? e.target.checked : e.target.value)
          }
          type={props.type}
          max={props.max}
          min={props.min}
          {...rest}
          className={classNames(
            props.classNames,
            props.name === 'antiquity'
              ? 'appearance-none block w-50'
              : 'appearance-none block w-full',
            isCheckBox ? 'p-2' : 'px-4 py-4',
            'mt-1 border border-primary-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm',
            props.antiquity ? 'w-50' : '',
          )}
        />
      )}
      {props.options && (
        <select
          ref={ref}
          onChange={(e) => props.onChange(e.target.value)}
          {...rest}
          className={classNames(
            props.classNames,
            props.name === 'antiquity'
              ? 'appearance-none block w-50'
              : 'appearance-none block w-full',
            isCheckBox ? 'p-2' : 'px-4 py-4',
            'mt-1 border border-primary-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm',
            props.antiquity ? 'w-50' : '',
          )}
        >
          <option key="" value="">
            Seleccione una opción
          </option>
          {props.options.map((option: any) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}

      {errorMessage && !isCheckBox && (
        <small className="text-red-500 ml-4">{errorMessage}</small>
      )}

      {props.label && isCheckBox && (
        <label
          htmlFor={props.name}
          className={`absolute ml-8 mt-1 text-sm font-medium ${
            errorMessage ? 'text-red-500' : 'text-primary-600'
          }`}
        >
          {props.label}
        </label>
      )}
    </div>
  );
});

NewInput.displayName = 'NewInput';

export default NewInput;
