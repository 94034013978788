import React, { useReducer } from 'react';
import { Modal } from '../ui/src';
import type { ReactNode } from 'react';
import {
  ModalPositionT,
  ModalSizeT,
} from '../ui/src/lib/components/Modal/Modal';

interface OpenOptions {
  title?: string;
  titleClassName?: string;
  titleHolderClassName?: string;
  closable?: boolean;
  position?: ModalPositionT;
  size?: ModalSizeT;
  onClose?: () => void;
}

interface ModalConfig {
  isOpen: boolean;
  title: string;
  titleClassName?: string;
  titleHolderClassName?: string;
  closable: boolean;
  position?: ModalPositionT;
  size?: ModalSizeT;
  onClose?: () => void;
}

interface ContextHolderProps {
  content: React.ReactNode;
}

type ModalType = [
  (options: OpenOptions) => void,
  ({ content }: ContextHolderProps) => ReactNode,
  () => void
];

const MODAL_INITIAL_STATE = Object.freeze({
  isOpen: false,
  title: '',
  closable: true,
  position: 'center',
  size: 'medium',
  onClose: () => {},
});

export const useModal = (): ModalType => {
  const [modalConfig, setModalConfig] = useReducer(
    (state: ModalConfig, newState: Partial<ModalConfig>) => ({
      ...state,
      ...newState,
    }),
    MODAL_INITIAL_STATE
  );

  const open = ({
    title,
    titleClassName,
    titleHolderClassName,
    position,
    size,
    onClose,
    closable = true,
  }: OpenOptions): void => {
    setModalConfig({
      isOpen: true,
      title,
      titleClassName,
      titleHolderClassName,
      position,
      size,
      onClose,
      closable,
    });
  };

  const close = (): void => {
    if (modalConfig.closable) {
      setModalConfig(MODAL_INITIAL_STATE);
      modalConfig.onClose && modalConfig.onClose();
    }
  };

  const ContextHolder = ({ content }: ContextHolderProps) => {
    if (!modalConfig.isOpen) {
      return null;
    }

    return (
      <Modal
        title={modalConfig.title}
        titleClassName={modalConfig.titleClassName}
        titleHolderClassName={modalConfig.titleHolderClassName}
        open={modalConfig.isOpen}
        position={modalConfig.position}
        size={modalConfig.size}
        onClose={modalConfig.closable ? close : undefined}
      >
        {content}
      </Modal>
    );
  };

  return [open, ContextHolder, close];
};
