import AppMap from '@/components/product/MapDrag';

interface MapZoneProps {
  coordinates: any
  setCoordinates?: any
}
function MapCoordinates({
  coordinates,
  setCoordinates
}: MapZoneProps) {
  return (
    <>
      <div className="px-4 mb-4 md:px-0">
        <AppMap
          coordinates={{
            lat: coordinates.latitude,
            lng: coordinates.longitude,
          }}
          setCoordinates={setCoordinates}
        />
      </div>
    </>
  );
}
export default MapCoordinates;
