import { useEffect } from 'react';
import styles from './RangeSlider.module.css';
import NumberFormat from 'react-number-format';

interface IRangeSliderProps {
  initialValue: number;
  min: {
    key: string;
    value: number;
  };
  max: {
    key: string;
    value: number;
  };
  step: number;

  setValue: (value: any) => void;
}

const RangeSlider = ({
  initialValue,
  min,
  max,
  step,
  setValue,
}: IRangeSliderProps) => {
  useEffect(() => {
    setValue(String(initialValue)); // Set initial value to a string
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center py-4">
      <div className="pr-4 grow-0 whitespace-nowrap">
        {min.value} {min.key}
      </div>
      <input
        className={`${styles.customRange} bg-primary-100 grow block h-1 outline-none rounded-full w-full appearance-none my-2 mr-auto selection:bg-white`}
        type="range"
        step={step}
        min={`${min.value}`}
        max={`${max.value}`}
        value={initialValue}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <div className="pl-4 grow-0 whitespace-nowrap">
        +{' '}
        <NumberFormat
          value={max.value}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
        />{' '}
        {max.key}
      </div>
    </div>
  );
};

export default RangeSlider;
