import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import NewInput from '../atoms/NewInput';
import { yupResolver } from '@hookform/resolvers/yup';
import Spinner from '../atoms/Spinner';
import { Button, Modal, MultiOptional, ToastContent } from '@buydepa-public/ui';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useRouter } from 'next/router';
import { useCustomMutation } from '@buydepa-public/graphql';
import { useToast } from '@buydepa-public/overlays';
import MapCoordinates from './MapCoordinates';
import { depaMatchFormSchema } from '@/helpers/schemas/depaMatchSchema';
import RangeSlider from '../atoms/RangeSlider/RangeSlider';
import NumberFormat from 'react-number-format';

type IModalDepaMatchProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

type ILocation = 'santiago' | 'las-condes';

export default function ModalDepaMatch({
  open,
  setOpen,
}: IModalDepaMatchProps) {
  const router = useRouter();
  const { open: openToast } = useToast();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [location, setLocation] = useState<string>('santiago');
  const [bathrooms, setBathrooms] = useState<number>(0);
  const [clientEmail, setClientEmail] = useState<string>('');
  const [price, setPrice] = useState<number>(0);
  const [salary, setSalary] = useState<string>('');
  const [debt, setDebt] = useState<string>('');
  const [clientName, setClientName] = useState<string>('');
  const [bedrooms, setBedrooms] = useState<number>(0);
  const [coordinates, setCoordinates] = useState<any>({
    latitude: -33.47269,
    longitude: -70.64724,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(depaMatchFormSchema),
  });

  const LOCATION_MAPPER = {
    santiago: { latitude: -33.4372, longitude: -70.6572 },
    cerrillos: { latitude: -33.5, longitude: -70.7167 },
    'cerro-navia': { latitude: -33.4219, longitude: -70.735 },
    conchalí: { latitude: -33.38, longitude: -70.675 },
    'el-bosque': { latitude: -33.5669, longitude: -70.675 },
    'estación-central': { latitude: -33.4589, longitude: -70.6989 },
    huechuraba: { latitude: -33.3678, longitude: -70.6339 },
    independencia: { latitude: -33.4128, longitude: -70.6658 },
    'la-cisterna': { latitude: -33.5289, longitude: -70.6628 },
    'la-florida': { latitude: -33.525, longitude: -70.5378 },
    'la-granja': { latitude: -33.5833, longitude: -70.5833 },
    'la-pintana': { latitude: -33.5828, longitude: -70.6339 },
    'la-reina': { latitude: -33.4428, longitude: -70.5319 },
    'las-condes': { latitude: -33.4167, longitude: -70.5833 },
    'lo-barnechea': { latitude: -33.35, longitude: -70.5167 },
    'lo-espejo': { latitude: -33.5219, longitude: -70.6869 },
    'lo-prado': { latitude: -33.445, longitude: -70.7258 },
    macul: { latitude: -33.4869, longitude: -70.6039 },
    maipú: { latitude: -33.5167, longitude: -70.7667 },
    ñuñoa: { latitude: -33.4539, longitude: -70.6039 },
    'pedro-aguirre-cerda': { latitude: -33.4661, longitude: -70.6336 },
    peñalolén: { latitude: -33.4861, longitude: -70.5333 },
    providencia: { latitude: -33.435, longitude: -70.6158 },
    pudahuel: { latitude: -33.4333, longitude: -70.7167 },
    quilicura: { latitude: -33.3608, longitude: -70.7289 },
    'quinta-normal': { latitude: -33.4269, longitude: -70.6989 },
    recoleta: { latitude: -33.4058, longitude: -70.64 },
    renca: { latitude: -33.3978, longitude: -70.7228 },
    'san-joaquín': { latitude: -33.4908, longitude: -70.6278 },
    'san-miguel': { latitude: -33.4858, longitude: -70.6494 },
    'san-ramón': { latitude: -33.5428, longitude: -70.6439 },
    vitacura: { latitude: -33.4, longitude: -70.6 },
  };

  const LOCATION_OPTIONS = [
    { label: 'Cerrillos', value: 'cerrillos' },
    { label: 'Cerro Navia', value: 'cerro-navia' },
    { label: 'Conchalí', value: 'conchalí' },
    { label: 'El Bosque', value: 'el-bosque' },
    { label: 'Estación Central', value: 'estación-central' },
    { label: 'Huechuraba', value: 'huechuraba' },
    { label: 'Independencia', value: 'independencia' },
    { label: 'La Cisterna', value: 'la-cisterna' },
    { label: 'La Florida', value: 'la-florida' },
    { label: 'La Granja', value: 'la-granja' },
    { label: 'La Pintana', value: 'la-pintana' },
    { label: 'La Reina', value: 'la-reina' },
    { label: 'Las Condes', value: 'las-condes' },
    { label: 'Lo Barnechea', value: 'lo-barnechea' },
    { label: 'Lo Espejo', value: 'lo-espejo' },
    { label: 'Lo Prado', value: 'lo-prado' },
    { label: 'Macul', value: 'macul' },
    { label: 'Maipú', value: 'maipú' },
    { label: 'Ñuñoa', value: 'ñuñoa' },
    { label: 'Pedro Aguirre Cerda', value: 'pedro-aguirre-cerda' },
    { label: 'Peñalolén', value: 'peñalolén' },
    { label: 'Providencia', value: 'providencia' },
    { label: 'Pudahuel', value: 'pudahuel' },
    { label: 'Quilicura', value: 'quilicura' },
    { label: 'Quinta Normal', value: 'quinta-normal' },
    { label: 'Recoleta', value: 'recoleta' },
    { label: 'Renca', value: 'renca' },
    { label: 'San Joaquín', value: 'san-joaquín' },
    { label: 'San Miguel', value: 'san-miguel' },
    { label: 'San Ramón', value: 'san-ramón' },
    { label: 'Santiago', value: 'santiago' },
    { label: 'Vitacura', value: 'vitacura' },
  ];

  const RENT_AVERAGE_OPTIONS = [
    { value: 'less_than_1_000_000', label: 'Menor a $1.000.000' },
    { value: '1_000_000_to_1_500_000', label: 'Entre $1.000.000 - $1.500.000' },
    { value: '1_500_000_to_2_000_000', label: 'Entre $1.500.000 - $2.000.000' },
    { value: '2_000_000_to_2_600_000', label: 'Entre $2.000.000 - $2.600.000' },
    { value: 'more_than_2_600_000', label: 'Mayor a $2.600.000' },
  ];

  const CREDIT_DEBT_OPTIONS = [
    { value: 'no', label: 'No tengo créditos' },
    { value: 'less_than_50_000', label: 'Menor o igual a $50.000' },
    { value: '50_001_to_150_000', label: 'Entre $50.001 - $150.000' },
    { value: '150_001_to_250_000', label: 'Entre $150.001 - $250.000' },
    { value: '250_001_to_450_000', label: 'Entre $250.001 - $450.000' },
    { value: 'more_than_450_000', label: 'Mayor a $450.000' },
  ];

  const TIPOLOGY_OPTIONS = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
  ];

  const setToInitialValues = () => {
    setClientEmail('');
    setClientName('');
    setSalary('');
    setDebt('');
    setBedrooms(0);
    setBathrooms(0);
    setLocation('santiago');
    setCoordinates(LOCATION_MAPPER.santiago);
    setPrice(0);
  };

  const [createOpportunity, createOpportunityLoading] = useCustomMutation({
    url: '/api/create-opportunity',
    onSuccess: () => {
      openToast({
        type: 'success',
        content: (
          <ToastContent
            title="Exitoso!"
            subtitle="Solicitud enviada correctamente!"
          />
        ),
        duration: 3000,
      });
    },
    onError: (err) => {
      openToast({
        type: 'error',
        content: <ToastContent title="Error" subtitle="Ha ocurrido un error" />,
      });
    },
  });
  const sendQuote = (opportunity: any) => {
    const response = new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append('opportunity', JSON.stringify(opportunity));
        createOpportunity(formData);
      } catch (err) {
        reject(new Error(' ~ Email api error!'));
      }
    });
    setToInitialValues();
    setOpen(false);
    return response;
  };

  const buttonOnSubmit = async () => {
    try {
      const opportunity = {
        clientEmail,
        clientName,
        salary,
        debt,
        bedrooms,
        bathrooms,
        location: {
          zone: location,
          coordinates: coordinates,
        },
        coordinates,
        price,
      };
      await sendQuote(opportunity);
      setOpen(false);
      setError(null);
      setSuccess('¡Gracias por tu solicitud, nos pondremos en contacto!');
    } catch (error: any) {
      setOpen(false);
      setError('Ocurrió un error al enviar la solicitud, inténtalo más tarde.');
      setSuccess(null);
    }
  };
  useEffect(() => {
    if (open) {
      setSuccess(null);
      setError(null);
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const loadingState = (
    <>
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full">
        <Spinner />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-buydepa-blue"
        >
          Enviando...
        </Dialog.Title>
      </div>
    </>
  );

  const errorState = (
    <>
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
        <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-buydepa-blue"
        >
          {error}
        </Dialog.Title>
      </div>
    </>
  );
  const successState = (
    <div className="flex flex-col items-center justify-center">
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-buydepa-blue"
        >
          <div>{success}</div>
          {/* <Button
            variant="fill"
            color="primary"
            className="flex items-center gap-4 mx-auto my-8"
          >
            <ArrowDownTrayIcon width={20} height={20} />
            <p>Descargar cotización</p>
          </Button> */}
        </Dialog.Title>
      </div>
    </div>
  );
  const disabled = !(
    clientEmail &&
    clientName &&
    salary &&
    debt &&
    bedrooms &&
    bathrooms &&
    location &&
    price !== 0 &&
    coordinates
  );
  const idleState = (
    <>
      <div className="text-center sm:mt-5 mb-6">
        <div className="text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-lg leading-6 text-black-300">
            Para encontrarte una oportunidad necesitamos
            <br /> los siguientes datos.
          </Dialog.Title>
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-6">
        <NewInput
          label="Nombre completo"
          placeholder="Ej: Martín Ruiz"
          type="text"
          errors={errors}
          onChange={(e: any) => setClientName(e.target.value)}
        />
        <NewInput
          label="Correo *"
          placeholder="Ej: martin@gmail.com"
          type="email"
          errors={errors}
          onChange={(e: any) => setClientEmail(e.target.value)}
        />
        <div className="relative mt-1 rounded-md shadow-sm">
          <NewInput
            label="Renta promedio últimos 3 meses *"
            type="text"
            errors={errors}
            options={RENT_AVERAGE_OPTIONS}
            onChange={(e: any) => setSalary(e.target.value)}
          />
        </div>
        <div className="relative mt-1 rounded-md shadow-sm">
          <NewInput
            label="¿Cuánto pagas al mes en créditos?*"
            type="text"
            errors={errors}
            options={CREDIT_DEBT_OPTIONS}
            onChange={(e: any) => setDebt(e.target.value)}
          />
        </div>
        <div className="relative mt-1 rounded-md shadow-sm">
          <NewInput
            label="¿Dormitorios?*"
            type="text"
            errors={errors}
            options={TIPOLOGY_OPTIONS}
            onChange={(e: any) => setBedrooms(e.target.value)}
          />
        </div>
        <div className="relative mt-1 rounded-md shadow-sm">
          <NewInput
            label="¿Baños?*"
            type="text"
            errors={errors}
            options={TIPOLOGY_OPTIONS}
            onChange={(e: any) => setBathrooms(e.target.value)}
          />
        </div>
        <div className="relative mt-1 rounded-md shadow-sm">
          <h3 className="font-bold text-primary pt-6 pb-2">
            Presupuesto máximo:{' '}
            <NumberFormat
              value={price}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
            />{' '}
            UF
          </h3>
          <RangeSlider
            setValue={(value) => setPrice(Number(value))}
            initialValue={price}
            min={{ key: 'UF', value: 0 }}
            max={{ key: 'UF', value: 10000 }}
            step={10}
          />
        </div>
      </div>
      <h3 className="font-bold text-primary pt-6 pb-2">
        ¿Dónde quiéres vivir? (Puedes arrastrar el marcador)
      </h3>
      <div className="relative mt-1 rounded-md shadow-sm">
        <select
          value={location}
          className="block w-50 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-buydepa-blue focus:border-buydepa-blue sm:text-sm rounded-md"
          onChange={(e) => {
            const value = e.target.value;
            setLocation(value);
            setCoordinates(
              LOCATION_MAPPER[value as keyof typeof LOCATION_MAPPER],
            );
          }}
        >
          <option value="">Selecciona una opción</option>
          {LOCATION_OPTIONS.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-6">
        <MapCoordinates
          coordinates={coordinates}
          setCoordinates={setCoordinates}
        />
      </div>
      <p className="text-sm mt-6 text-gray-400 mb-4">* Campos obligatorios</p>
      <div className="flex justify-end gap-6">
        <Button
          type="button"
          variant="fill"
          color="primary"
          onClick={buttonOnSubmit}
          disabled={disabled}
        >
          ¡Notificame!
        </Button>
      </div>
      <br />
      <br />
      <br />
      <br />
    </>
  );
  return (
    <Modal
      title="Buscar Depa"
      open={open}
      onClose={() => setOpen(false)}
      size="large"
    >
      {createOpportunityLoading
        ? loadingState
        : error
        ? errorState
        : success
        ? successState
        : idleState}
    </Modal>
  );
}
