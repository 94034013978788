import { useState, useEffect } from 'react';

export default function useWindowSize() {
  const [size, setSize] = useState<(number | null)[]>([null, null]);

  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}
